import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import {
  Navbar,
  NavItem,
  Nav,
  NavLink,
} from 'reactstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from '../Link/Link';
import history from '../../history';
import { alert } from '../../actions/alerts';
import {
  getUser,
  getCurrentLocale,
} from '../../selectors';
import LanguageSwitcher from '../LanguageSwitcher';
import styles from './styles.scss';


const messages = defineMessages({
  pay: {
    id: 'navigation.pay',
    defaultMessage: 'Pay Bill',
    description: 'About link in header',
  },
  myUnits: {
    id: 'navigation.myUnits',
    defaultMessage: 'My Unit(s)',
    description: 'My Units link in header',
  },
  settings: {
    id: 'navigation.settings',
    defaultMessage: 'Settings',
    description: 'Settings link in header',
  },
  logout: {
    id: 'header.logout',
    defaultMessage: 'Logout',
    description: 'Logout button text in header',
  },
  faqs: {
    id: 'header.faqs',
    defaultMessage: 'FAQs',
    description: 'FAQs button text in header',
  },
  faqsUrl: {
    id: 'header.faqsUrl',
    defaultMessage: 'https://www.storage-mart.com/self-storage/faqs/your-account',
    description: 'URL to the FAQs page',
  },
});
// for change navigation item name please use messages array item or dictionary
const links = [
  {
    id: 0,
    name: 'pay',
    path: '/',
    icon: 'attach_money',
    external: false,
  },
  {
    id: 1,
    name: 'myUnits',
    path: '/my-units',
    icon: 'domain',
    external: false,
  },
  {
    id: 2,
    name: 'settings',
    path: '/settings',
    icon: 'person',
    external: false,
  },
  {
    id: 3,
    name: 'faqs',
    pathToken: 'faqsUrl',
    icon: 'help',
    target: '_blank',
    external: true,
  },
];

class Navigation extends React.Component {
    static propTypes = {
      user: PropTypes.object,
      currentPath: PropTypes.string,
    };
    constructor(props, context) {
      super(props, context);
      this.state = {
        currentPath: null,
      };
      this.isCurrentPath = this.isCurrentPath.bind(this);
    }

    componentWillMount() {
      if (history.location && history.location.pathname) {
        this.setState({ currentPath: history.location.pathname });
      }
    }

    componentDidMount() {
      this.unlisten = history.listen((location) => {
        this.onRouteChange(location.pathname);
      });
    }

    onRouteChange(path) {
      this.setState({ currentPath: path });
    }

    isCurrentPath(path, currentPath) {
      if (path !== '/') {
        return currentPath.includes(path);
      }
      return path === currentPath;
    }

    render() {
      const { user, intl } = this.props;
      const { currentPath } = this.state;
      const isLoggedIn = user && user.id && user.id.length > 0;
      return (
        <Navbar
          translate="true"
          className={`${styles.nav}`}
        >
          <div
            className={styles.nav_menu}
          >
            <LanguageSwitcher className={styles.LanguageSwitcher} />
            {(currentPath && isLoggedIn) ?
              <Nav className={`ml-auto ${styles.nav_list}`}>
                {links.map(item => (
                  <NavItem
                    key={item.id}
                    className={`${styles.nav_item} ${this.isCurrentPath(item.path, currentPath) ?
                      styles.nav_item_active : ''}`}
                    active={this.isCurrentPath(item.path, currentPath)}
                  >
                    {item.external
                      ? (
                        <a
                          href={intl.formatMessage(messages[item.pathToken])}
                          className={classNames(styles.nav_link, 'nav-link')}
                          target={item.target}
                        >
                          <i
                            className={`material-icons ${styles.nav_link_icon}`}
                            aria-hidden="true"
                          >{item.icon}
                          </i>
                          <FormattedMessage {...messages[`${item.name}`]} />
                        </a>
                      )
                      : (
                        <NavLink
                          to={item.path}
                          tag={Link}
                          className={styles.nav_link}
                          onClick={this.props.onExpand}
                        >
                          <i
                            className={`material-icons ${styles.nav_link_icon}`}
                            aria-hidden="true"
                          >{item.icon}
                          </i>
                          <FormattedMessage {...messages[`${item.name}`]} />
                        </NavLink>
                      )
                    }
                  </NavItem>
                ))}
              </Nav>
              :
              '' }
          </div>
        </Navbar>

      );
    }
}

const mapState = state => ({
  user: getUser(state),
  currentLocale: getCurrentLocale(state),
});

const mapDispatch = dispatch => ({
  actions: bindActionCreators({ alert }, dispatch),
});

export default injectIntl(connect(mapState, mapDispatch)(withStyles(styles)(Navigation)));
