import _ from 'lodash';
import { DEFAULT_PROVIDER, TOTALRECALL_PROVIDER, STOREDGE_SOFTWARE, US_COUNTRY_CODE } from '../constants';
import { FR_CA } from '../helpers/localeHelper';
// Tenant Selectors
export const getCurrentTenant = state => state.tenants.tenant;
export const getCurrentTenants = state => state.tenants;

export const getCurrentTenantId = state => state.tenants.tenant && state.tenants.tenant.id;
export const getCurrentTenantGroupingId = state => state.tenants.tenant && state.tenants.tenant.tenant_grouping_id;
// Ledger Selectors
export const getCurrentPrepays = state => state.tenants.ledgers &&
  _.map(state.tenants.ledgers, ledger => (
    { id: ledger.id, prepays: groupByMonth(ledger.available_prepay_amounts) }
  ));
function groupByMonth(payArray) {
  if (payArray.length === 0) return payArray;
  const minPrepayMonth = _.minBy(payArray, 'prepay_month');
  const maxPrepayMonth = _.maxBy(payArray, 'prepay_month');
  const monthsOut = [...Array(maxPrepayMonth.prepay_month)];
  const groupedPays = monthsOut.map((month, index) => {
    if (index + 1 >= minPrepayMonth.prepay_month) {
      const amountSum = _.sumBy(payArray, (payment) => {
        if (payment.prepay_month <= index + 1) {
          return (payment.amount + payment.tax_total);
        }
        return 0;
      });
      return { month: index + 1, amount: amountSum };
    }
    return 0;
  });
  return groupedPays;
}
export const getCurrentLedgerIds = state => state.tenant && state.tenant.current_ledger_ids;
export const getCurrentLedgers = state => state.tenants.ledgers;

// Unit Selectors
export const getCurrentUnits = state => state.tenants.ledgers &&
  state.tenants.ledgers.map(ledger => ledger.unit);

// Facility Selectors
export const getCurrentFacilityId = state => _.get(state, 'tenants.facility.id');
export const getCurrentFacility = state => state.tenants && state.tenants.facility;
export const getCurrentFacilityCountry = state => _.get(state, 'tenants.facility.address.country');

// Software Selectors
export const hasDefaultSoftwareProvider = state =>
  state.tenants.tenant.facility.software_provider === DEFAULT_PROVIDER;
export const hasTotalRecallSoftwareProvider = state =>
  state.tenants.tenant.facility.software_provider === TOTALRECALL_PROVIDER;
export const hasStoredgeSoftwareProvider = state =>
  state.tenants.tenant?.facility?.software_provider === STOREDGE_SOFTWARE;

// User Selectors
export const getUser = state => state.user;
export const getUserId = state => state.user.id;

// Intl Selectors
export const getCurrentLocale = state => state.intl.locale;
export const getCurrentLocaleCountry = (state) => {
  const possibleCountry = state.intl.locale.split('-')[1];
  if (possibleCountry) {
    return possibleCountry.toLowerCase();
  }
  return US_COUNTRY_CODE;
};
export const getCurrentLocaleFacilityOverride = (state) => { // eslint-disable-line id-length
  const currentLocale = state.intl.locale;
  if (_.get(state, 'tenants.facility.address.state', '').toLowerCase() === 'qc' &&
    currentLocale.toLowerCase() === 'en-us') {
    return FR_CA;
  }
  return currentLocale;
};

// Loader Selectors
export const getAccountLoading = (state, tenantId) => {
  // Account Selector Component loading
  let accountLoading;
  const accIndex = state.loading.components.findIndex(obj => obj.component === `account_${tenantId}`);
  if (accIndex === -1) {
    accountLoading = true;
  } else {
    accountLoading = state.loading.components[accIndex].loading;
  }

  return (accountLoading);
};

export const getRecentyActivityLoading = (state, tenantId) => {
  // Recent Activity Component loading
  let recentActivityLoading;
  const raIndex = state.loading.components.findIndex(obj => obj.component === `recentActivity_${tenantId}`);
  if (raIndex === -1) {
    recentActivityLoading = true;
  } else {
    recentActivityLoading = state.loading.components[raIndex].loading;
  }

  return recentActivityLoading;
};

export const getPayLoading = (state, tenantId) => {
  return (getRecentyActivityLoading(state, tenantId) || getAccountLoading(state, tenantId));
};

export const getDocumentsLoading = (state, tenantId) => {
  // Document Component loading
  let documentsLoading;
  const docIndex = state.loading.components.findIndex(obj => obj.component === `documents_${tenantId}`);
  if (docIndex === -1) {
    documentsLoading = true;
  } else {
    documentsLoading = state.loading.components[docIndex].loading;
  }
  return documentsLoading;
};

export const getInvoicesLoading = (state, tenantId) => {
  // Invoices Component loading
  let invoicesLoading;
  const invIndex = state.loading.components.findIndex(obj => obj.component === `invoices_${tenantId}`);
  if (invIndex === -1) {
    invoicesLoading = true;
  } else {
    invoicesLoading = state.loading.components[invIndex].loading;
  }
  return invoicesLoading;
};

export const getPaymentListLoading = (state, tenantId) => {
  // PaymentsFullList Component loading
  let pflLoading;
  const pflIndex = state.loading.components.findIndex(obj => obj.component === `paymentsFullList_${tenantId}`);
  if (pflIndex === -1) {
    pflLoading = true;
  } else {
    pflLoading = state.loading.components[pflIndex].loading;
  }
  return pflLoading;
};

// eslint-disable-next-line arrow-body-style
export const getMyUnitsLoading = (state, tenantId) => {
  // return if any are loading
  return (
    getAccountLoading(state, tenantId) ||
    getDocumentsLoading(state, tenantId) ||
    // getInvoicesLoading(state, tenantId) ||
    getPaymentListLoading(state, tenantId));
};
